<template >
    <div class="static-page" :style="getBg" v-if="page">
            <video class="videobg" v-if="page.custom_video_bg" :src="page.custom_video_bg" type="video/mp4" autoplay loop muted></video>
            <image-component class="imgbg" v-else-if="page.custom_image_bg" :path="page.custom_image_bg" />
            <div class="text-left mt-5 ml-5 mr-5 text-block" :class="{'text-on-img': getImageBg}" v-html="pageContent"  > </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import ImageComponent from "../components/base-components/ImageComponent";

    export default {
        name: "CustomPageScreen",
        components: {ImageComponent},
        data() {
            return {
                pageContent: null,
                page: null,
            }
        },
        computed:{
            getBg(){
                if(!this.page.custom_video_bg && !this.page.custom_image_bg){
                    return "background-color:" + this.page.custom_bg_color
                }
                return false
            },
            getImageBg(){
                if(!this.page.custom_video_bg && this.page.custom_image_bg){
                    return "background: url('"+ this.page.custom_image_bg + "');" ;
                }
                return false
            }
        },
        methods: {
            ...mapActions(['GET_CHANNEL_PAGE']),
            getContent(){
              // TODO: remove to computed
                this.pageContent = this.convertEditorJsonToHtml(this.page.content) ?? '';
            },
            getPageContent(){
                this.GET_CHANNEL_PAGE(this.$route.meta.id)
                    .then((data) => {
                        this.page = data.data;
                        this.getContent();
                    });
            }
        },
        created() {
            this.getPageContent();
        },
        watch: {
            $route(to, from) {
                this.getPageContent();
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/css/colors";
    .videobg {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 70px;
        left: 0;
        opacity: 0.4;
        z-index: -1;
    }
    .imgbg{
        position: relative;
        left: 0;
        opacity: 0.5;
        height: auto;
        width: 100%;
    }
    .text-block{
        text-shadow: 4px 2px 8px rgba($text-secondary-rgb,.71);

        overflow-y: scroll;
        top: 70px;
        height: 80vh;
    }
    .text-on-img{
        position: absolute;
    }
    .text-block::-webkit-scrollbar {
        width: 1em;
    }
    .static-page{
        margin-bottom: -3rem !important;
    }
</style>

